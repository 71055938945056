@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

/* General Styles */
body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;

}





/* Admin Page */
.full-width-container {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.card {
  margin-top: 0;
  margin-bottom: 0;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.command-card {
  margin-top: 20px; /* Ajustar si es necesario */
}

.card-header {
  background-color: #4CAF50;
  color: white;
  padding: 15px;
  font-size: 1.25rem;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-header button {
  background-color: #388E3C;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  width: auto;
}

.card-header button i {
  margin-right: 8px;
}

.card-header button:hover {
  background-color: #2E7D32;
}

.card-body {
  padding: 20px;
}

.table-responsive {
  margin-top: 20px;
  overflow-x: auto;
}

.table {
  width: 100%;
  max-width: 1200px; /* Limit the max width of the table */
  margin: 0 auto; /* Center the table */
}

.table thead th {
  background-color: #f5f5f5;
  color: #212121;
  text-align: left;
  font-weight: 700;
}

.table thead th input {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
}

.table tbody td {
  text-align: left;
  white-space: nowrap;
  padding: 8px;
}

.table tbody tr:hover {
  background-color: #f0f0f0;
}

.table tfoot th {
  text-align: center;
}

.table tfoot th input {
  width: 100%;
  box-sizing: border-box;
}
.platillos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
  margin-top: 20px;
}

.platillo-item {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s;
}

.platillo-item:hover {
  background-color: #e2e6ea;
  transform: scale(1.05);
}

.nav-tabs-custom {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #dee2e6;
  width: 200px; /* Ajusta según lo necesario */
}

.nav-tabs-custom .nav-link {
  margin-bottom: 5px;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  transition: background-color 0.2s, color 0.2s;
}

.nav-tabs-custom .nav-link.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.nav-tabs-custom .nav-link:hover {
  background-color: #e2e6ea;
  color: #495057;
}
.birthday-banner {
  background-color: #ffe066;
  color: #c23616;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 15px;
  animation: bounce 1.5s infinite;
}

.birthday-icon {
  font-size: 1.5rem;
  margin-right: 8px;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
