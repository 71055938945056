.form-wrapper {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: flex-start; /* Alinea al inicio verticalmente */
  position: relative;
  width: 100%;
  padding: 0; /* Elimina padding */
  margin: 0; /* Elimina margin */
  box-sizing: border-box;
}

.form-container {
  background: white;
  border: none; /* Elimina bordes */
  box-shadow: none; /* Elimina sombra */
  padding: 20px; /* Reduce padding */
  margin: 0; /* Elimina margen */
  max-width: 800px;
  width: 100%; /* Asegura que ocupe todo el espacio disponible */
}

.form-title {
  font-size: 2.5rem;
  color: #f3e357;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(255, 0, 0, 0.336);
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.form-control-lg:focus {
  box-shadow: 0 0 10px rgba(0, 0, 255, 0.2);
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.suggestion-item:hover {
  background-color: #f8f9fa;
}

.form-actions {
  margin-top: 20px;
}

.form-btn {
  width: 48%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease;
}

.form-btn-success {
  background-color: #28a745;
  color: white;
  border: none;
}

.form-btn-success:hover {
  background-color: #218838;
}

.form-btn-danger {
  background-color: #dc3545;
  color: white;
  border: none;
}

.form-btn-danger:hover {
  background-color: #c82333;
}

.mesa-list-container {
  width: 18%; /* Ajusta el ancho para que sea proporcional al formulario */
  background-color: white; /* Fondo blanco para la lista */
  padding: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: fit-content; /* Ajusta la altura para que encaje el contenido */
  margin-right: 30px; /* Añade un margen mayor a la derecha */
  box-sizing: border-box;
}

.mesa-item {
  transition: background-color 0.3s ease;
}

.mesa-item:hover {
  background-color: rgba(255, 223, 0, 0.3); /* Cambia el color acorde al fondo */
}

.list-group-item {
  border: none;
  padding: 15px 20px;
  margin-bottom: 10px;
}

.form-actions button {
  margin-right: 10px; /* Margen derecho para separar los botones */
}

.form-btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.5rem;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-btn-primary:hover {
  background-color: #0056b3;
}

/* Estilos para los botones de mesa */
.mesa-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Ajustar columnas */
  gap: 15px;
  justify-content: center;
}

.mesa-item {
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  border: none;
}

.mesa-item:hover {
  transform: scale(1.1);
}

.mesa-item.ocupada {
  background-color: #ff4d4d !important; /* Rojo fuerte para ocupadas */
  color: #fff;
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
}

.mesa-item.disponible {
  background-color: #4caf50 !important; /* Verde fuerte para disponibles */
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
}

.mesa-item.selected {
  border: 2px solid #007bff;
  background-color: #007bff !important;
  color: #fff !important;
}


/* Styles for the Grid */
.mesa-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columnas para que sea cuadrada */
  gap: 10px;
  margin: 20px 0;
}

.mesa-item {
  padding: 15px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
}

.mesa-item.selected {
  background-color: #007bff;
  color: white;
}

.mesa-item:hover {
  background-color: #007bff;
  color: white;
  transform: scale(1.05);
}

/* Button Styles */
button.close {
  font-size: 1.5rem;
  color: #333;
  opacity: 1;
  transition: color 0.3s ease;
}

button.close:hover {
  color: #007bff;
}


/* Lista de Pendientes */
.pendientes-list-container {
  width: 18%; /* Ajusta el ancho para que sea proporcional al formulario */
  background-color: white; /* Fondo blanco para la lista */
  padding: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: fit-content; /* Ajusta la altura para que encaje el contenido */
  margin-right: 30px; /* Añade un margen mayor a la derecha */
  box-sizing: border-box;
  font-family: 'Roboto Mono', monospace; /* Fuente aplicada a la lista */
}

.pendientes-list-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center; /* Centra el título */
  color: #333; /* Cambia el color del título */
}

.pendientes-list {
  list-style-type: none;
  padding: 0;
}

.pendientes-item {
  background-color: #f9f9f9;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Añade sombra a los elementos */
}

.pendientes-item:hover {
  background-color: #f0f0f0;
}

/* Estilo personalizado para el modal */
.custom-modal {
  max-width: 800px; /* Ancho máximo del modal */
  margin-top: 10vh; /* Más cerca de la parte superior */
}

.modal-content {
  background-color: #fff;
  padding: 30px; /* Aumenta el padding para un diseño más espacioso */
  border-radius: 20px; /* Más redondeado */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 100%;
  height: auto;
}

.mesa-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 20px;
  justify-content: center;
}

.mesa-item {
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  border: none;
}

.mesa-item:hover {
  transform: scale(1.1);
}

.mesa-item.ocupada {
  background-color: #ff4d4d !important; /* Rojo fuerte para ocupadas */
  color: #fff;
  box-shadow: 0 0 15px rgba(255, 0, 0, 0.5);
}

.mesa-item.disponible {
  background-color: #4caf50 !important; /* Verde fuerte para disponibles */
  color: #fff;
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.5);
}

.mesa-item.selected {
  border: 2px solid #007bff;
  background-color: #007bff !important;
  color: #fff !important;
}

.modal-footer .btn {
  background-color: #6c757d;
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  transition: background-color 0.3s ease;
}

.modal-footer .btn:hover {
  background-color: #5a6268;
}


.mesa-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Mantén 3 columnas */
  gap: 10px;
  margin: 20px 0;
}

.mesa-item {
  padding: 15px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  width: 100%;
  height: 100%;
}

.mesa-item.selected {
  background-color: #007bff;
  color: white;
}

.mesa-item:hover {
  background-color: #007bff;
  color: white;
  transform: scale(1.05);
}

/* Estilo para centrar mejor el botón de cerrar */
.modal-footer .btn {
  background-color: #6c757d;
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  transition: background-color 0.3s ease;
  align-self: center;
}

.modal-footer .btn:hover {
  background-color: #5a6268;
}
.custom-dropdown {
  max-height: 150px;
  overflow-y: auto;
  z-index: 1050; /* Ajuste de la jerarquía para evitar que se oculte */
  background-color: #ffffff; /* Fondo blanco sólido */
  border: 1px solid #ced4da;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  position: absolute; /* Posición absoluta para que siga al input */
  width: 100%; /* Asegura que el dropdown tenga el mismo ancho que el input */
}

.custom-item {
  padding: 10px;
  cursor: pointer; /* Cambia el cursor para indicar que es interactivo */
}

.custom-item:hover {
  background-color: #f8f9fa;
}

.custom-item:active {
  background-color: #e9ecef;
}

.form-control {
  background-color: #ffffff; /* Fondo sólido */
  border: 1px solid #ced4da; /* Borde bien definido */
  border-radius: 0.25rem;
}
@media (max-width: 768px) {
  .form-container {
    width: 100%; /* Ocupa el 100% del ancho disponible en pantallas pequeñas */
    padding: 20px;
  }

  .mesa-list-container {
    width: 100%; /* Asegura que la lista de pendientes también ocupe el 100% */
    margin-top: 20px; /* Añade margen superior para separar los elementos */
  }

  .form-title {
    font-size: 1.5rem; /* Ajusta el tamaño de la fuente para pantallas pequeñas */
  }
  .sidebar {
    display: none; /* Oculta el menú en pantallas pequeñas */
  }
}
.form-title {
  font-size: 1.5rem; /* Reduce el tamaño del título en pantallas pequeñas */
}
.narrow-datepicker .MuiInputBase-root {
  width: 100% !important;
}