.login-container {
    padding: 20px;
  }
  
  .login-box {
    width: 100%;
    max-width: 400px;
  }
  
  .logo-img {
    height: 100px;
    width: 100px;
  }
  
  @media (max-width: 576px) {
    .login-box {
      padding: 2rem;
      box-shadow: none;
      border: 1px solid #ddd;
    }
    .logo-img {
      height: 40px;
      width: 40px;
    }
  }
  