/* App.css */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Mono', monospace;
  height: 100%;
 /* Fondo unificado */
}

.app-container {
  display: flex;
  height: 100vh;
  background: inherit; /* Hereda el fondo del body */
}


.main-content {
  margin-left: 60px;
  padding: 20px;
  flex: 1;
  background: inherit; /* Hereda el fondo del contenedor */
  overflow: auto;
  height: 100vh;
}
body {
  background: linear-gradient(to right, #f39c12, #f1c40f); /* Fondo degradado */
  font-family: 'Roboto Mono', monospace; /* Fuente para todo el cuerpo */
  color: #333; /* Color de texto predeterminado */
}

.form-container {
  background: #ffffff; /* Fondo blanco del formulario */
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 70%; /* Ajusta el ancho */
  max-width: 800px;
  margin: 20px auto;
  justify-content: flex-start; 
}
/* Estilos generales de la barra lateral */
.sidebar {
  background-color: #212529;
  width: 100px;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Cambiar a flex-start para alinear al inicio */
  z-index: 1000;
  transition: transform 0.3s ease;
  padding-top: 10px; /* Ajusta este valor según el espacio superior deseado */
}

.sidebar a:first-child {
  margin-top: 0; /* Quita cualquier margen superior */
}


/* Mantener barra lateral siempre visible en pantallas grandes */
.sidebar.open {
  transform: translateX(0);
}

/* Estilos para los enlaces de la barra lateral */
.sidebar a {
  margin: 5px 0;
  color: #ffffff;
  font-size: 2.5rem;
  text-align: center;
  transition: transform 0.2s, background-color 0.2s;
}

.sidebar a:hover {
  transform: scale(1.2);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 10px;
}

.bg-custom {
  background-color: #212529;
}

.sidebar-icon {
  font-size: 2rem;
  transition: transform 0.2s ease-in-out;
}

.sidebar-icon:hover {
  transform: scale(1.2);
  color: #ffc107;
}

/* Ocultar el menú lateral y mostrar el botón en dispositivos móviles */
.menu-toggle-btn {
  display: none;
}

@media (max-width: 768px) {
  .menu-toggle-btn {
    display: block;
    position: fixed;
    top: 15px;
    left: 15px;
    background-color: #ffc107;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebar {
    transform: translateX(-100%); /* Oculto por defecto en móviles */
    width: 200px;
    left: 0;
    top: 0;
    height: 100vh;
    position: fixed;
    z-index: 1000;
  }

  .sidebar.open {
    transform: translateX(0); /* Mostrar cuando esté abierto */
  }
}
